<template>
  <div class="footer">
    <div class="info">
      <div class="content">
        <div class="logos">
          <img src="../assets/logo3.png" />
          <div class="hr"></div>
          <img src="../assets/newlogo.png" />
        </div>
        <div class="org">
          <div>
            主办：中共北京市委组织部
            承办：北京市党组织建设电教中心（北京市干部教育培训服务中心）
          </div>
          <div>
            技术支持电话：010-55568666（9：00-18：00）
            技术支持QQ：541226839（9：00-18：00）
          </div>
        </div>
      </div>
    </div>
    <div class="link">
      <div class="content">
        <div class="link-desc">
          <img src="../assets/icon-link.png" />友情链接：
        </div>
        <div class="site" @click="go('https://www.12371.cn/')">共产党员网</div>
        <div class="site" @click="go('http://www.people.com.cn/')">人民网</div>
        <div class="site" @click="go('http://www.xinhuanet.com/')">新华网</div>
        <div class="site" @click="go('https://www.cctv.com/')">央视网</div>
        <div class="hr"></div>
        <div class="site" @click="go('http://www.beijing.gov.cn/')">
          首都之窗
        </div>
        <div class="site" @click="go('http://www.bjdj.gov.cn/index.html')">
          北京组工网
        </div>
        <div class="hr"></div>
        <div class="site" @click="go('http://www.nlc.cn/')">国家图书馆</div>
        <div class="site" @click="go('http://www.ouchn.edu.cn/')">
          国家开放大学
        </div>
      </div>
    </div>
    <div class="icp">
      <div class="content">浙ICP备19045839号</div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    go(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.footer {
  .info {
    height: 120px;
    background: #66b1ff;
    .logos {
      float: left;
      img {
        display: inline-block;
        vertical-align: top;
        margin-top: 32px;
        height: 56px;
        margin-right: 25px;
      }
      .hr {
        display: inline-block;
        vertical-align: top;
        height: 50px;
        width: 2px;
        background: #8cc5ff;
        margin-top: 35px;
        margin-right: 25px;
      }
    }
    .org {
      float: right;
      margin-top: 30px;
      line-height: 30px;
      color: #fff;
      font-size: 16px;
      font-family: AlibabaPuHuiTi_2_55_Regular;
      font-weight: 400;
    }
  }
  .link {
    height: 80px;
    background: #409eff;
    color: #fff;
    .content {
      height: 80px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .link-desc {
      font-size: 16px;
      display: flex;
      flex-direction: row;
      align-items: center;
      font-weight: bold;
      img {
        height: 20px;
        margin-right: 5px;
      }
    }
    .hr {
      width: 2px;
      height: 20px;
      background: #79bbff;
      margin: 0 35px;
    }
    .site {
      font-size: 16px;
      font-weight: 400;
      margin: 0 26px;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
    }
  }
  .icp {
    font-size: 14px;
    height: 40px;
    background: #2391ff;
    line-height: 40px;
    color: #fff;
    text-align: center;
  }
}
</style>
