<template>
  <component :is="compName"></component>
</template>

<script>
import MobilePlayPage from "./MobilePlayPage.vue";
import PlayPage from "./PlayPage.vue";

export default {
  components: {
    MobilePlayPage: MobilePlayPage,
    PlayPage: PlayPage,
  },
  data() {
    return {
      compName: "",
    };
  },
  methods: {
    adaptor() {
      const width = screen.width;
      if (width < 900) {
        this.compName = "MobilePlayPage";
      } else {
        this.compName = "PlayPage";
      }
    },
  },
  created() {
    this.ind = setInterval(this.adaptor, 500);
    this.adaptor();
  },
  beforeDestroy() {
    clearInterval(this.ind);
  },
};
</script>
