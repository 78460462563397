var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"playing"},[_c('XHeader'),_c('XBrand'),_c('div',{staticClass:"player-container"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"name"},[_vm._v(" "+_vm._s(_vm.course ? _vm.course.courseName : "")+" ")]),_vm._m(0),_c('div',{staticClass:"video-info"},[_c('span',{staticClass:"iconfont"},[_vm._v("")]),_c('span',[_vm._v(_vm._s(_vm.course ? _vm.course.studyTime : "--"))])])])]),_c('div',{staticClass:"course"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"pannel"},[_c('div',{staticClass:"tabs"},[_c('div',{staticClass:"tab",class:{ 'tab-active': _vm.currentTab == 'chapter' },on:{"click":function($event){_vm.currentTab = 'chapter'}}},[_vm._v(" 课程章节 ")]),_c('div',{staticClass:"tab",class:{ 'tab-active': _vm.currentTab == 'desc' },on:{"click":function($event){_vm.currentTab = 'desc'}}},[_vm._v(" 课程介绍 ")])]),(_vm.currentTab == 'desc')?_c('div',{staticClass:"info",domProps:{"innerHTML":_vm._s(_vm.course ? _vm.course.content : '')}}):_vm._e(),(_vm.currentTab == 'chapter')?_c('div',{staticClass:"info"},[_c('div',{staticClass:"playlist"},_vm._l((_vm.playerList),function(chapter,ind){return _c('div',{key:'chapter_' + ind + '_' + chapter.id,staticClass:"chapter"},[_c('div',{staticClass:"chapter-title"},[_vm._v(" "+_vm._s(chapter.name)+" ")]),_c('div',{staticClass:"chapter-list"},_vm._l((chapter.videos),function(video){return _c('div',{key:'video_' + video.videoId,staticClass:"video",class:{ 'video-active': _vm.currentVideo == video },on:{"click":function($event){return _vm.play(video)}}},[_c('el-progress',{staticClass:"progress",attrs:{"type":"circle","percentage":video.studyProgress.studyTime > video.duration * 1000
                        ? 100
                        : Math.floor(
                            (video.studyProgress.studyTime * 100) /
                              (video.duration * 1000)
                          ),"width":50}}),_c('div',{staticClass:"video-title"},[_c('span',{staticClass:"iconfont"},[_vm._v(" ")]),_c('span',[_vm._v(_vm._s(video.name))])])],1)}),0)])}),0)]):_vm._e()])])]),_c('XFooter')],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"playbox"},[_c('div',{staticClass:"player",attrs:{"id":"player-container"}})])
}]

export { render, staticRenderFns }