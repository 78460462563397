import axios from "axios";
import config from "@/config";
import router from "@/router";

const inst = axios.create({
  baseURL: config.apiBasePath,
});

inst.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem("token");
    config.headers["token"] = token;
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    console.log("request error:" + error);
    return Promise.resolve({
      status: 500,
      error: "请求失败,请检查网络:" + error,
    });
  }
);

inst.interceptors.response.use(
  (response) => {
    let data = response.data;
    if (!data) {
      console.log(response);
      console.error("返回数据异常");
    }
    if (data.code === 9999) {
      console.log(data);
    }
    return data;
  },
  (error) => {
    if (error && error.response) {
      if (error.response.status === 403) {
        router.replace("/login");
        return Promise.resolve({
          status: 500,
          error: "重新登录",
        });
      }
      if (error.response.status === 404) {
        return Promise.resolve({
          status: 500,
          error: "404接口路径不存在",
        });
      }
    }
    console.error("response error:" + error);
    return Promise.resolve({
      status: 500,
      error: "请求处理失败",
    });
  }
);

export const post = inst.post;
export const get = inst.get;

export default {
  install(Vue) {
    Vue.prototype.$get = get;
    Vue.prototype.$post = post;
  },
};
