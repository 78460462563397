import XHeader from "../components/XHeader.vue";
import XBrand from "../components/XBrand.vue";
import XFooter from "../components/XFooter.vue";
let player = null;

export default {
  components: {
    XHeader,
    XBrand,
    XFooter,
  },
  data() {
    return {
      playerList: [],
      course: null,
      isShow: false,
      currentChapter: 0,
      currentVideo: null,
      playing: false,
      lastSyncTime: 0,
      signature: "",
      currentTab: "chapter",
    };
  },
  computed: {
    userId() {
      return this.$route.query.userId;
    },
    courseCode() {
      return this.$route.query.courseCode;
    },
  },
  methods: {
    async loadCourse() {
      const rs = await this.$post("player/load", {
        userId: this.userId,
        courseCode: this.courseCode,
      });
      if (rs.status == 200) {
        this.playerList = rs.payload.playerList;
        this.course = rs.payload.course;

        this.checkStudyTime();
        for (var i = 0; i < this.playerList.length; i++) {
          var chapter = this.playerList[i];
          for (var j = 0; j < chapter.videos.length; j++) {
            var video = chapter.videos[j];
            this.play(video);
            setTimeout(() => {
              this.isShow = false;
            }, 1000);
            return;
          }
        }
      }
    },
    async login() {
      const rs = await this.$post("player/login", {
        userId: this.userId,
        courseCode: this.courseCode,
        elearningToken: this.$route.query.elearningToken,
        timestampe: this.$route.query.timestampe,
        platform: this.$route.query.platform,
      });
      if (rs.status == 200) {
        localStorage.setItem(this.$route.query.elearningToken, "1");
        sessionStorage.setItem("token", rs.payload);
        this.loadCourse();
      } else {
        sessionStorage.setItem("error", rs.error);
        this.$router.replace("/login");
      }
    },
    selectChapter(chapter, ind) {
      if (this.currentChapter == ind) {
        this.currentChapter = null;
      } else {
        this.currentChapter = ind;
      }
    },
    checkStudyTime() {
      if (this._indOfCheckStudyTime) {
        clearTimeout(this._indOfCheckStudyTime);
        this._indOfCheckStudyTime = null;
      }

      // 判断当前是否在播放,播放的,给当前视频学习时间+1秒
      if (this.playing && this.currentVideo) {
        this.currentVideo.studyProgress.studyTime =
          this.currentVideo.studyProgress.studyTime + 1000;

        if (Date.now() - this.lastSyncTime > 1000 * 5) {
          this.record(this.currentVideo);
          this.lastSyncTime = Date.now();
        }
      }

      this._indOfCheckStudyTime = setTimeout(this.checkStudyTime, 1000);
    },
    play(video) {
      if (this.currentVideo == video) {
        return;
      }
      if (this.currentVideo) {
        this.record(this.currentVideo);
      }
      let start = localStorage.getItem("process_" + video.videoId);
      start = start ? parseInt(start) : 0;
      if (player == null) {
        player = window.EduPlayer.create({
          parent: document.getElementById("player-container"),
          autoStart: true,
          videoData: {
            videoId: video.videoId,
            signature: video.signature,
            start: start,
          },
        });
      } else {
        player.destroy();
        player = window.EduPlayer.create({
          parent: document.getElementById("player-container"),
          autoStart: true,
          videoData: {
            videoId: video.videoId,
            signature: video.signature,
            start: start,
          },
        });
      }
      // 读取播放进度,进度保存到服务器
      player.$on("onPlay", this.onPlay);
      player.$on("onTimeupdate", this.onTimeupdate);
      player.$on("onPlayEnd", this.onPlayEnd);
      player.$on("onPause", this.onPause);
      this.currentVideo = video;
    },
    onPlay() {
      this.playing = true;
    },
    onTimeupdate(data) {
      console.log(data);
      var currentTime = data.currentTime;
      var duration = data.duration;
      var percent = Math.round((currentTime * 100) / duration);
      // 如果进度有增加,则更新和记录
      if (
        percent &&
        this.currentVideo &&
        percent > this.currentVideo.studyProgress.playProcess
      ) {
        this.currentVideo.studyProgress.playProcess = percent;
        this.record(this.currentVideo);
      }
      if (this.currentVideo) {
        localStorage.setItem(
          "process_" + this.currentVideo.videoId,
          currentTime
        );
      }
    },
    onPlayEnd() {
      if (this.currentVideo) {
        localStorage.removeItem("process_" + this.currentVideo.videoId);
      }
      this.playing = false;
      // 如果有下一个视频 自动播放
      var found = false;
      for (var i = 0; i < this.playerList.length; i++) {
        var chapter = this.playerList[i];
        for (var j = 0; j < chapter.videos.length; j++) {
          var video = chapter.videos[j];
          if (found) {
            if (this.currentChapter != i) {
              this.selectChapter(chapter, i);
            }
            this.play(video);
            return;
          }
          if (video === this.currentVideo) {
            found = true;
          }
        }
      }
    },
    onPause() {
      this.playing = false;
    },
    record(video) {
      this.$post("player/record", {
        videoId: video.videoId,
        courseId: video.courseId,
        playProcess: video.studyProgress.playProcess,
        duration: video.duration,
      })
        .then(function () {})
        .catch(function (error) {
          console.log(error);
        });
    },
  },
  mounted() {
    // 如果有token参数,且未使用过,进行登录换取token
    const elearningToken = this.$route.query.elearningToken;
    if (elearningToken && !localStorage.getItem(elearningToken)) {
      this.login();
      return;
    }
    const token = sessionStorage.getItem("token");
    if (!token) {
      this.login();
    } else {
      this.loadCourse();
    }
  },
};
