<template>
  <div class="playing">
    <div class="player" id="player-container"></div>
    <div class="pannel">
      <div class="tabs">
        <div
          class="tab"
          :class="{ 'tab-active': currentTab == 'chapter' }"
          @click="currentTab = 'chapter'"
        >
          课程章节
        </div>
        <div
          class="tab"
          :class="{ 'tab-active': currentTab == 'desc' }"
          @click="currentTab = 'desc'"
        >
          课程介绍
        </div>
      </div>
      <div
        class="info"
        v-if="currentTab == 'desc'"
        v-html="course ? course.content : ''"
      ></div>
      <div class="info" v-if="currentTab == 'chapter'">
        <div class="playlist">
          <div
            class="chapter"
            v-for="(chapter, ind) in playerList"
            :key="'chapter_' + ind + '_' + chapter.id"
          >
            <div class="chapter-title">
              {{ chapter.name }}
            </div>
            <div class="chapter-list">
              <div
                class="video"
                :class="{ 'video-active': currentVideo == video }"
                v-for="video in chapter.videos"
                :key="'video_' + video.videoId"
                @click="play(video)"
              >
                <el-progress
                  type="circle"
                  :percentage="
                    video.studyProgress.studyTime > video.duration * 1000
                      ? 100
                      : Math.floor(
                          (video.studyProgress.studyTime * 100) /
                            (video.duration * 1000)
                        )
                  "
                  :width="50"
                  class="progress"
                ></el-progress>
                <div class="video-title">
                  <span class="iconfont">&#xe6a2; </span>
                  <span>{{ video.name }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Play from "./play";
export default {
  ...Play,
  name: "MobilePlayPage",
};
</script>

<style lang="scss" scoped>
.playing {
  .player {
    width: 100%;
    height: 60vw;
  }

  .playlist {
    font-size: 14px;

    .chapter {
      padding: 10px 20px;
      .chapter-title {
        line-height: 50px;
        font-size: 15px;
      }
      .chapter-list {
        .video {
          height: 50px;
          line-height: 50px;
          margin-left: 20px;
          margin-bottom: 10px;
          cursor: pointer;
          &:hover {
            color: #66b1ff;
          }
          .progress {
            display: inline-block;
            vertical-align: middle;
          }
          .video-title {
            display: inline-block;
            vertical-align: middle;
            margin-left: 20px;
            .iconfont {
              font-size: 26px;
            }
            span {
              display: inline-block;
              vertical-align: middle;
              &:last-child {
                overflow: hidden;
                width: calc(100vw - 180px);
                text-overflow: ellipsis;
                white-space: nowrap;
              }
            }
          }
        }
        .video-active {
          color: #409eff;
        }
      }
    }
  }
  .pannel {
    .tabs {
      background: #fff;
      height: 52px;
      display: flex;
      .tab {
        flex: 0 0 50%;
        height: 50px;
        line-height: 50px;
        text-align: center;
        font-size: 16px;
        border-bottom: 2px solid #ddd;
      }
      .tab-active {
        color: #409eff;
        border-bottom: 2px solid #409eff;
      }
    }
    .info {
      min-height: 200px;
      height: calc(100vh - 60vw - 70px);
      padding: 10px;
      font-size: 14px;
      color: #333;
      overflow: auto;
    }
  }
}
</style>
