<template>
  <div id="app" :class="{ wap: isWap, notwap: !isWap }">
    <router-view />
  </div>
</template>

<script>
export default {
  data() {
    return {
      isWap: false,
    };
  },
  created() {
    const ua = navigator.userAgent;
    if (ua.indexOf("iPhone") > -1 || ua.indexOf("Android") > -1) {
      let script = document.getElementById("wap_flexible");
      if (!script) {
        script = document.createElement("script");
        script.setAttribute(
          "src",
          "//edu-cms.nosdn.127.net/topics/js/wap_flexible_8254ebe28c3ef10de10b5befac286b8c.js"
        );
        script.setAttribute("id", "wap_flexible");
        document.body.appendChild(script);
      }

      let link = document.getElementById("res_base_wap");
      if (!link) {
        link = document.createElement("link");
        link.setAttribute(
          "href",
          "//edu-cms.nosdn.127.net/topics/css/res_base_wap_bf7fa73141ee9a696b08098cbe903925.css"
        );
        link.setAttribute("rel", "stylesheet");
        link.setAttribute("id", "res_base_wap");
        document.head.appendChild(link);
      }
      this.isWap = true;
    }
  },
};
</script>

<style lang="scss">
body,
html {
  margin: 0;
  padding: 0;
}
#app {
  font-family: AlibabaPuHuiTi_2_55_Regular, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  white-space: pre-line;
}
.content {
  display: block;
  width: 1200px;
  margin: 0 auto;
}
.wap {
  .content {
    width: 100%;
  }
}
.notwap {
  .m-popover ul {
    padding: 0;
  }
  .u-edu-h5player-controlwrap .m-popover-quality li {
    text-align: center;
    padding: 0;
  }
}

@font-face {
  font-family: "iconfont";
  src: url("//at.alicdn.com/t/c/font_2376631_shyis0ncmia.woff2?t=1670557951478")
      format("woff2"),
    url("//at.alicdn.com/t/c/font_2376631_shyis0ncmia.woff?t=1670557951478")
      format("woff"),
    url("//at.alicdn.com/t/c/font_2376631_shyis0ncmia.ttf?t=1670557951478")
      format("truetype");
}

@font-face {
  font-family: "AlibabaPuHuiTi_2_55_Regular";
  src: url("https://cdnjsgjelearning.goldgov.com/data_files/AlibabaPuHuiTi2/AlibabaPuHuiTi-2-55-Regular/AlibabaPuHuiTi-2-55-Regular.ttf")
    format("truetype");
}

.iconfont {
  font-family: iconfont;
  font-style: normal;
}

::-webkit-scrollbar {
  width: 4px;
}
::-webkit-scrollbar-thumb {
  border-radius: 0;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.2;
  background: #ddd;
}
::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: #fff;
}
</style>
