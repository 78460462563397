<template>
  <div class="playing">
    <XHeader></XHeader>
    <XBrand></XBrand>
    <div class="player-container">
      <div class="content">
        <div class="name">
          {{ course ? course.courseName : "" }}
        </div>
        <div class="playbox">
          <div class="player" id="player-container"></div>
        </div>
        <div class="video-info">
          <span class="iconfont">&#xeb2a;</span>
          <span>{{ course ? course.studyTime : "--" }}</span>
        </div>
      </div>
    </div>
    <div class="course">
      <div class="content">
        <div class="pannel">
          <div class="tabs">
            <div
              class="tab"
              :class="{ 'tab-active': currentTab == 'chapter' }"
              @click="currentTab = 'chapter'"
            >
              课程章节
            </div>
            <div
              class="tab"
              :class="{ 'tab-active': currentTab == 'desc' }"
              @click="currentTab = 'desc'"
            >
              课程介绍
            </div>
          </div>
          <div
            class="info"
            v-if="currentTab == 'desc'"
            v-html="course ? course.content : ''"
          ></div>
          <div class="info" v-if="currentTab == 'chapter'">
            <div class="playlist">
              <div
                class="chapter"
                v-for="(chapter, ind) in playerList"
                :key="'chapter_' + ind + '_' + chapter.id"
              >
                <div class="chapter-title">
                  {{ chapter.name }}
                </div>
                <div class="chapter-list">
                  <div
                    class="video"
                    :class="{ 'video-active': currentVideo == video }"
                    v-for="video in chapter.videos"
                    :key="'video_' + video.videoId"
                    @click="play(video)"
                  >
                    <el-progress
                      type="circle"
                      :percentage="
                        video.studyProgress.studyTime > video.duration * 1000
                          ? 100
                          : Math.floor(
                              (video.studyProgress.studyTime * 100) /
                                (video.duration * 1000)
                            )
                      "
                      :width="50"
                      class="progress"
                    ></el-progress>
                    <div class="video-title">
                      <span class="iconfont">&#xe6a2; </span>
                      <span>{{ video.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <XFooter></XFooter>
  </div>
</template>

<script>
import Play from "./play";
export default {
  ...Play,
  name: "MobilePlayPage",
};
</script>

<style lang="scss" scoped>
.playing {
  .player-container {
    background: #262626;
    font-size: 0;
    padding-bottom: 30px;
    .name {
      color: #fff;
      height: 100px;
      line-height: 100px;
      font-size: 30px;
      font-family: AlibabaPuHuiTi_2_85_Bold;
      font-weight: 400;
    }
    .playbox {
      border: 1px solid #333;
    }
    .player {
      height: 400px;
      background: #222;
      box-sizing: border-box;
    }
    .video-info {
      height: 30px;
      line-height: 30px;
      color: #666;
      font-size: 14px;
      .iconfont {
        font-size: 20px;
      }
      span {
        display: inline-block;
        vertical-align: middle;
      }
    }
  }

  .playlist {
    font-size: 14px;

    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 0;
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      opacity: 0.2;
      background: #444;
    }
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
      border-radius: 0;
      background: #888;
    }

    .chapter {
      padding: 10px 20px;
      .chapter-title {
        line-height: 50px;
        font-size: 15px;
      }
      .chapter-list {
        .video {
          height: 50px;
          line-height: 50px;
          margin-left: 20px;
          margin-bottom: 10px;
          cursor: pointer;
          &:hover {
            color: #66b1ff;
          }
          .progress {
            display: inline-block;
            vertical-align: middle;
          }
          .video-title {
            display: inline-block;
            vertical-align: middle;
            margin-left: 20px;
            .iconfont {
              font-size: 26px;
            }
            span {
              display: inline-block;
              vertical-align: middle;
            }
          }
        }
        .video-active {
          color: #409eff;
        }
      }
    }
  }
  .course {
    background: #fff;
    min-height: 200px;
    padding: 50px 0;
    .pannel {
      .tabs {
        background: #66b1ff;
        color: #fff;
        height: 50px;
        line-height: 50px;
        font-size: 18px;
        .tab {
          display: inline-block;
          cursor: pointer;
          width: 150px;
          text-align: center;
        }
        .tab-active {
          background: #409eff;
        }
      }
      .info {
        background: #ecf5ff;
        min-height: 200px;
        padding: 10px;
        font-size: 16px;
        color: #333;
      }
    }
  }
}
</style>
