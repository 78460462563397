<template>
  <div>缺少登录信息{{ error ? ":" + error : "" }}</div>
</template>

<script>
export default {
  data() {
    return {
      error: sessionStorage.getItem("error"),
    };
  },
};
</script>
