import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import request from "./utils/request";
import "element-ui/lib/theme-chalk/index.css";

import { Progress } from "element-ui";

Vue.use(Progress);

Vue.config.productionTip = false;

Vue.use(request);

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
